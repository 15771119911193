export const getErrorMessage = (path) => {

    const feedbackMessageHelper = {
        "error": {
            "credentials-incorrect": "Incorrect Credentials, please try again",
            "insufficient-parameters": "Please ensure all fields are filled correctly",
            "email-already-in-use": "Email Address already in use",
            "illegal-team-selection": "Your team selection does not comply with the rules",
            "team-too-expensive": "Your team is too expensive",
            "player-does-not-exist": "Player not found",
            "team-does-not-exist": "Team not found",
            "incorrect-team-size": "You have selected the incorrect number of players",
            "user-already-created-team": "You have already created a team",
            "invalid-email": "Invalid email address",
            "password-reset-request-is-expired": "Link has expired",
            "incorrect-club": "Scorecard does not belong to your club",
            "cannot-process-transaction": "Unable to process transaction",
            "user-already-paid": "You've already paid!",
            "rule-already-exists": "Import rule already exists",
            "team-not-found": "Team not found",
            "invalid-upload": "Invalid file",
            "user-not-found": "Incorrect Credentials, please try again",
            "match-already-exists": "Match is already imported",
            "player-already-has-match": "Player is already added to this match",
            "wildcard-already-played": "Wildcard has already been played",
            "generic": "Oops! Something went wrong"
        }
    }

    return feedbackMessageHelper.error[`${path}`] || feedbackMessageHelper.error.generic;
}