import { 
    changeCaptain, 
    getTransferStatus, 
    setWildcardGameweek,
    getTeamData,
    activateTripleCaptain,
    makeTransfers,
    getTeamList,
    deleteTeam,
    createTeam,
    getArchiveTeamStandingYears,
    changeTeamName,
    getNetTransfers
} from './team';

import {
    getRules,
    login,
    forgotPassword,
    resetPassword,
    getUser,
    createAccount,
    updateUserDetails,
    deleteAccount
} from './user';

import {
    getPlayerList,
    getPlayer,
    editPlayer,
    deletePlayer,
    addPlayer,
    importMultiplePlayers,
    updateMultiplePlayers,
    editPlayerMatchData,
    removePlayerFromMatch,
    addPlayerToMatch,
    getArchivePlayerStandingYears,
    getArchivePlayerGameweekData,
    getPlayerPriceChanges,
    updatePlayerPriceChanges,
    uploadCSVFile
} from './player';

import {
    getHomepageStats,
    getAdmin,
    updateAdmin,
    getAdminForAdminPage,
    getImportedMatches,
    updateMatch,
    importMatch,
    deleteMatch,
    reImportMatch,
    getUsers,
    updateUser,
    getPlayersToImport,
    getMatchesWithoutPlayer,
    uploadLogo,
    deleteLogo,
    addPointsDeduction,
    deletePointsDeduction,
    updatePointsStructure,
    getEntryFundsSummary,
    addImportMatchRule,
    getMatchImportRules,
    deleteMatchImportRule,
    getMatchData,
    clearCache,
    importManualMatch,
    assignPlayerToMatchData,
    endSeason,
    getLatestMatchesWithPlayers
} from './admin';

import {
    getPaymentToken,
    generateTransaction
} from './payment';

export const api = {
    team: {
        changeCaptain,
        getTransferStatus,
        setWildcardGameweek,
        getTeamData,
        activateTripleCaptain,
        makeTransfers,
        getTeamList,
        deleteTeam,
        createTeam,
        getArchiveTeamStandingYears,
        changeTeamName,
        getNetTransfers
    },
    user: {
        getRules,
        login,
        forgotPassword,
        resetPassword,
        getUser,
        createAccount,
        updateUserDetails,
        deleteAccount
    },
    player: {
        getPlayerList,
        getPlayer,
        editPlayer,
        deletePlayer,
        addPlayer,
        importMultiplePlayers,
        updateMultiplePlayers,
        editPlayerMatchData,
        removePlayerFromMatch,
        addPlayerToMatch,
        getArchivePlayerStandingYears,
        getArchivePlayerGameweekData,
        getPlayerPriceChanges,
        updatePlayerPriceChanges,
        uploadCSVFile
    },
    admin: {
        getHomepageStats,
        getAdmin,
        updateAdmin,
        getAdminForAdminPage,
        getImportedMatches,
        updateMatch,
        importMatch,
        deleteMatch,
        reImportMatch,
        getUsers,
        updateUser,
        getPlayersToImport,
        getMatchesWithoutPlayer,
        uploadLogo,
        deleteLogo,
        addPointsDeduction,
        deletePointsDeduction,
        updatePointsStructure,
        getEntryFundsSummary,
        addImportMatchRule,
        getMatchImportRules,
        deleteMatchImportRule,
        getMatchData,
        clearCache,
        importManualMatch,
        assignPlayerToMatchData,
        endSeason,
        getLatestMatchesWithPlayers
    },
    payment: {
        getPaymentToken,
        generateTransaction
    }
}