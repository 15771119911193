import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form, Select, InputNumber, Modal, message, Upload, Tabs, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import StrategyManager from '../strategies/StrategyManager';

import useAdmin from "hooks/useAdmin";

const tabKeys = {
    UPLOAD_CSV: 'upload-csv',
    ADD_PLAYER: 'add-player'
}

const AddPlayer = ({isVisible, handleConfirm, handleCancel}) => {

    const { adminData } = useAdmin();

    const matchDataStrategyManager = new StrategyManager();
    const matchData = matchDataStrategyManager.getMatchDataStrategy(adminData.getSport(), adminData.hasAutoUpdate());

    const [activeTabKey, setActiveTabKey] = useState(tabKeys.ADD_PLAYER);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [uploadedCSV, setUploadedCSV] = useState([]);

    const changeActiveTabKey = (tabKey) => {
        console.log('CHANGE ACTIVE TAB KEY', tabKey);
        setActiveTabKey(tabKey);
    }

    const submitValues = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setLoading(true);

                const { firstName, lastName, value, positionName, pcId } = values;

                try {
                    const response = await api.player.addPlayer({
                        firstName, 
                        lastName,
                        value, 
                        positionName, 
                        pcId
                    });

                    const newPlayer = response.data;
                    const newPlayersArray = [newPlayer];
            
                    message.success("Player successfully added");

                    form.resetFields();
                    handleConfirm(newPlayersArray);
                    setLoading(false);
                } catch (error) {
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));

                    setLoading(false);
                }
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const handleUploadCSV = async () => {
        if (! uploadedCSV.length) {
            message.error('No file uploaded');
            return;
        }
        setLoading(true);

        try {
            const response = await api.player.uploadCSVFile({ importCSV: uploadedCSV[0] });

            const newPlayers = response.data.importedPlayers;
    
            message.success(`[${newPlayers.length}] players successfully imported`, 7);

            if (response.data.failedImportRows.length > 0) {
                message.warning(`CSV rows [${response.data.failedImportRows.join()}] failed to import`, 7);
            }

            setUploadedCSV([]);
            handleConfirm(newPlayers);
            setLoading(false);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));

            setLoading(false);
        }
                
    }

    const uploadProps = {
        onRemove: () => {
            setUploadedCSV([]);
        },
        beforeUpload: (file) => {
            setUploadedCSV([file]);
            return false;
        },
        fileList: uploadedCSV
    };
   

    return (
        <Modal 
            title="Add Player"
            open={isVisible}
            onCancel={handleCancel}
            okText="Submit"
            cancelText="Close"
            footer={[
                <Button
                    key="cancel"
                    disabled={loading}
                    onClick={handleCancel}
                    >
                    Cancel
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={loading} 
                    onClick={activeTabKey === tabKeys.ADD_PLAYER ? submitValues : handleUploadCSV}
                    >
                    {activeTabKey === tabKeys.ADD_PLAYER ? 'Submit' : 'Upload'}
                </Button>,
            ]}
            >
            <Tabs
                defaultActiveKey={tabKeys.ADD_PLAYER} 
                onChange={changeActiveTabKey}
                items={[
                    {
                        label: 'Add Player',
                        key: tabKeys.ADD_PLAYER,
                        children: 
                            <Form layout='vertical' form={form}>
                                <Form.Item 
                                    name="firstName" 
                                    rules={[{ required: true, message: 'Please input a first name' }]} 
                                    label="First Name"
                                    >
                                    <Input placeholder="First Name" disabled={loading} />
                                </Form.Item>
                                <Form.Item 
                                    name="lastName" 
                                    rules={[{ required: true, message: 'Please input a last name' }]} 
                                    label="Last Name"
                                    >
                                    <Input placeholder="Last Name" disabled={loading} />
                                </Form.Item>
                                <Form.Item 
                                    name="value" 
                                    rules={[{ required: true, message: 'Please input a value' }]} 
                                    label={`Value (${adminData.currency()}m)`}
                                    >
                                    <InputNumber 
                                        placeholder={`Value (${adminData.currency()}m)`}
                                        style={{width: '100%'}}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        step={.1} 
                                        disabled={loading}
                                        />
                                </Form.Item>
                                <Form.Item 
                                    name="positionName" 
                                    rules={[{ required: true, message: 'Please select a position' }]} 
                                    label="Position"
                                    >
                                    <Select placeholder="Position" disabled={loading}>
                                        {matchData.getPositions().map(position => (
                                            <Select.Option value={position}>{position}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {adminData.sportIsCricket() && adminData.hasAutoUpdate() && 
                                    <Form.Item name="pcId" label='Play Cricket ID'>
                                        <InputNumber 
                                            disabled={loading}
                                            placeholder="Play Cricket ID"
                                            style={{width: '100%'}}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                    </Form.Item>
                                }
                            </Form>
                    },
                    {
                        label: 'Import Players via CSV',
                        key: tabKeys.UPLOAD_CSV,
                        children:
                            <>
                                <Typography.Paragraph>Please ensure that csvs are uploaded with the data in the following order: 'First Name', 'Last Name', 'Position', 'Value'</Typography.Paragraph>

                                <Upload 
                                    name="CSV File" 
                                    disabled={loading}
                                    {...uploadProps}
                                    >
                                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                                </Upload>
                            </>
                    }
                ]}
                >
            </Tabs>
        </Modal>
    )
}

AddPlayer.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
}

export default AddPlayer;