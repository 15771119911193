import React, { useState, useReducer, useEffect } from 'react';
import { Button, Row, Modal, Result, notification, message, Affix, Alert } from 'antd';
import { useNavigate } from "react-router-dom";

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import { CreateTeamContext } from './../CreateTeam/index';
import { reducer, reducerActions, initialState } from './../CreateTeam/reducer';
import { SelectTeam } from './../CreateTeam/components';
import { api } from 'api';
import { FccSection, PageTitle } from 'components';
import ConfirmModal from './partials/ConfirmModal';

import useAdmin from "hooks/useAdmin";
import useUser from "hooks/useUser";

const Transfers = () => {

    const [modal, contextHolder] = Modal.useModal();

    const [state, dispatch] = useReducer(reducer, initialState);

    const [originalPlayers, setOriginalPlayers] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [wildcardIsUpdating, setWildcardIsUpdating] = useState(false);

    const { adminData } = useAdmin();
    const { userData, loggedOut } = useUser();
    
    const changePageTitle = useChangePageTitle();
    const navigate = useNavigate();

    const getTransferStatus = async () => {

        dispatch({
            type: reducerActions.isReady,
            payload: false
        });

        dispatch({
            type: reducerActions.setForTransfers,
            payload: true
        })

        try {
            const result = await api.team.getTransferStatus(userData.teamId);
            const nextPlayers = result.data.nextPlayers;

            setOriginalPlayers(nextPlayers);

            dispatch({
                type: reducerActions.setProfitLoss, 
                payload: result.data.profitLoss
            });

            dispatch({
                type: reducerActions.setNextPlayers, 
                payload: nextPlayers
            });

            dispatch({
                type: reducerActions.setTransfersAvailable, 
                payload: adminData.seasonHasNotStarted() ? 'Unlimited' : result.data.transfersAvailable
            });

            dispatch({
                type: reducerActions.selectPlayer, 
                payload: { selectedPlayers: nextPlayers, selectedRowKeys: nextPlayers.map(player=> player.id)}
            });

            dispatch({
                type: reducerActions.setWildcardGameweek, 
                payload: result.data.wildcardGameweek
            });

        } catch (error) {
            console.log('error_14', error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        dispatch({
            type: reducerActions.isReady,
            payload: true
        });
        
    };

    /* Get initial players */
    useEffect(() => {
        changePageTitle('Transfers');
        
        if (! loggedOut && userData?.teamId) {
            getTransferStatus();
        }
    }, []);

    /* Submit Transfers */
    const confirm = async () => {
        setConfirmLoading(true);

        const selectedPlayerIds = state.selectedPlayers.map(selectedPlayer => {
            return selectedPlayer.id;
        });

        if (! selectedPlayerIds.length) {
            message.error("No transfers made");
            setConfirmLoading(false);
            return;
        }
        
        try {
            await api.team.makeTransfers(userData.teamId, selectedPlayerIds);

            // Redirect to myTeam page and display message
            notification.success({
                message: "Success",
                description: "Transfers successfully completed",
                key: 1,
                duration: 6
            });

            navigate(`/team/${userData.teamId}`);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setConfirmLoading(false);
    }

    const calculateTransferPenalty = () => {
        if (
            adminData.seasonHasNotStarted() ||
            state.wildcardGameweek === adminData.getGameweek() +1 ||
            state.transfersIncurringPenalties <= 0
        ) {
            return '-0';
        }

        const transferDeduct = adminData.getTransferDeduct();
        return state.transfersIncurringPenalties * transferDeduct;
    }

    const handleResetTransfers = () => {
        dispatch({
            type: reducerActions.selectPlayer, 
            payload: { selectedPlayers: originalPlayers, selectedRowKeys: originalPlayers.map(player=> player.id)}
        });
    }

    const MemoizedWildcardButton = React.memo(function WildcardButton() {

        const nextGameweek = adminData.getGameweek() +1;
        
        const activateWildcard = () => {
            modal.confirm({
                title: 'Activate Wildcard',
                content: 'Are you sure you want to activate your wildcard?',
                onOk: async () => {
                    setWildcardIsUpdating(true);
                    try {
                        await api.team.setWildcardGameweek(userData.teamId, nextGameweek);
    
                        message.success('Wildcard active!');
    
                    } catch (error) {
                        const errorMessage = error.response ? error.response.data.error : 'generic';
                        message.error(getErrorMessage(errorMessage));
                        setWildcardIsUpdating(false);
                        return;
                    }

                    dispatch({
                        type: reducerActions.setWildcardGameweek, 
                        payload: nextGameweek
                    });

                    setWildcardIsUpdating(false);
                },
                onCancel() {
                    // console.log('Cancel');
                },
            });
        }

        // active now!
        if (state.wildcardGameweek === adminData.getGameweek() +1) {
            return <Alert message="Wildcard Active!" type="success" showIcon />
        }

        // never been used
        if (! state.wildcardGameweek) {
            return <Button 
                loading={wildcardIsUpdating}
                disabled={wildcardIsUpdating || adminData.seasonHasNotStarted() || confirmLoading}
                type='primary' 
                ghost={! state.wildcardGameweek}
                key="playWildcard"
                onClick={() => activateWildcard()}
                size='small'
                >
                    Play Wildcard
                </Button>
        }

        // already been played
        return <Button type='primary' size='small' key="playWildcard" disabled>Wildcard Unavailable</Button>
    });

    if (loggedOut || ! userData?.teamId) {
        return (
            <Result 
                title="Unauthorised Access"
                subTitle="You must log in and create a team to make transfers" 
                status="warning" 
                />
        );
    }

    if (adminData.seasonHasEnded()) {
        return (
            <Result 
                title="Unauthorised Access"
                subTitle="Your current season has ended" 
                status="warning" 
                />
        );
    }

    return (
        <CreateTeamContext.Provider value={{ state, dispatch }}>  
            {contextHolder}
            <PageTitle title="Transfers" extra={[<MemoizedWildcardButton key="wildcard" />]} />

            {! adminData.transferWindowIsOpen() ? (
                <Result 
                    title="Transfer window closed" 
                    subTitle="The transfer window has been closed by the administrator"
                    status="error" 
                />
            ) : (
                <>
                    <div>
                        <SelectTeam />
                    </div>

                    <FccSection>
                        <Affix offsetBottom={32}>
                            <Row type="flex" justify="space-between" align="middle" className="affix-submit-save affix--shadow">
                                    <Button
                                        danger
                                        onClick={handleResetTransfers}
                                        >
                                        Reset
                                    </Button>
                                    <Button 
                                        disabled={state.hasErrors || state.requestedTransfers.length < 1} 
                                        type="primary" 
                                        onClick={() => setModalIsVisible(true)}
                                        >
                                        Make Transfers
                                    </Button>
                            </Row>
                        </Affix>
                    </FccSection>

                    <ConfirmModal
                        isVisible={modalIsVisible}
                        handleCancel={() => setModalIsVisible(false)}
                        isLoading={confirmLoading}
                        handleConfirm={confirm}
                        additional={<MemoizedWildcardButton />}
                        transferPenalty={calculateTransferPenalty}
                    />
                </>
            )}
        </CreateTeamContext.Provider>
    )
}

export default Transfers;